import React, { useEffect, useReducer, useRef, useState } from 'react';
import './App.css';
import { amber, blue, red } from "@mui/material/colors";
import { AlertDialog } from "./components/GSHelperComponents/ErrorMessage";
import { BrowserRouter as Router } from "react-router-dom";
import { GSProvider } from "./components/GlassSphereContext/GlassSphereContext";
import { APIURL, websiteRoutes } from "./Views/Routes";
import axios from "axios";
import { ResponseCode } from "./components/GSHelperComponents/ResponseCodes";
import { useLocation } from 'react-router-dom';
import "fontsource-roboto"
import DocumentationRootframe from "./components/GSHelperComponents/DocumentationRootFrame";
import { logout, notificationEnabled, sessionValid, setSessionInvalid } from "./components/Auth/AuthHandler";
import ConfirmDialog from "./components/dialogs/ConfirmDialog";
import useConfirm from "./components/dialogs/useConfirm";
import LoadingDialog from "./components/dialogs/LoadingDialog";
import { createTheme, CssBaseline, responsiveFontSizes, Snackbar, Stack, ThemeProvider } from "@mui/material";

import ResponsiveDrawer from "./components/Drawer/ResponsiveDrawer";
import Box from "@mui/material/Box";
import ErrorDialog from "./components/dialogs/ErrorDialog";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import { AddAlert, Close, Info, Visibility, Warning } from "@mui/icons-material";
import { v4 as uuidv4 } from 'uuid';

import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import { messaging, onMessageListener, tryregisterMessaging } from "./components/dialogs/notification/firebase";
import { AlertTitle } from "@mui/material";
import { SnackbarProvider, useSnackbar } from "notistack";
import useNetworkStatus from "./components/GSHelperComponents/NetworkStatus";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";


function App() {
    const [loggedIn, setLoggedIn] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [loading, isLoading] = useState(false)
    const [darkMode, setDarkMode] = useState(false)
    const [sessionExpired, setSessionExpired] = useState(false)
    const [broadcast, setBroadcast] = useState(false)
    const [pendingRequests, setPendingRequests] = useState([])
    const [alreadyOpen, setAlreadyOpen] = useState(false)
    const { confirm } = useConfirm()
    const [newVersionAvailable, setNewVersionAvailable] = useState(false)
    const [visionalert, setVisionAlert] = useState(false)
    const [visionalertText, setVisionAlertText] = useState({ title: "", body: "", url: "" })
    const [waitingWorker, setWaitingWorker] = useState({})
    const [onlineStatus, setOnlineStatus] = useState()
    const { isOnline } = useNetworkStatus();
    const [snackbarMap, setSnackbarMap] = useState(new Map())
    const [privateModus, setPrivateModus] = useState(false)
    const vertical = 'top'
    const horizontal = 'center'
    const v1 = 'TOP'
    const h1 = 'RIGHT'

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleCloseSnackbar = uuid => {
        closeSnackbar(snackbarMap.get(uuid))
    }

    const onServiceWorkerUpdate = (registration) => {
        setWaitingWorker(registration && registration.waiting)
        setNewVersionAvailable(true)
        updateServiceWorker()
    };

    const updateServiceWorker = () => {


        navigator.serviceWorker.addEventListener('controllerchange', function () {
            // Ensure the new service worker is controlling the page before reloading
            if (!navigator.serviceWorker.controller) return;
            window.location.reload();
        });
        setNewVersionAvailable(false);

        // waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
        // setNewVersionAvailable(false)
        // window.location.reload();
    }

    window.addEventListener('error', (event) => {
        if (event.message.includes("Unexpected token '<'")) {
            window.location.reload();
        }
    });

    const showAlertKamera = () => {
        window.location.href = visionalertText.url
    }

    useEffect(() => {

        // tryregisterMessaging()

        if (messaging) {
            onMessage(messaging, (payload) => {
                var messageuuid = uuidv4();
                var snackid = enqueueSnackbar(payload?.notification?.body,
                    {
                        anchorOrigin: { horizontal: "right", vertical: "bottom" },
                        persist: true,
                        variant: 'error',
                        action: key => (
                            <Grid>
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: "#761414",
                                        borderColor: "white"
                                    }}
                                    size="medium"
                                    startIcon={<Visibility />}
                                    onClick={() =>
                                        window.location.href = payload.data.url
                                    }>ANZEIGEN</Button>
                                <IconButton onClick={e => handleCloseSnackbar(messageuuid)}>
                                    <Close style={{ color: "white" }} />
                                </IconButton>
                            </Grid>)
                    }
                );
                snackbarMap.set(messageuuid, snackid)
            });
        } else {
            var isflutter = typeof window.flutter_inappwebview !== 'undefined'
            if (!isflutter)
                setPrivateModus(true)
        }
        if (process.env.NODE_ENV === 'production') {
            serviceWorkerRegistration.register(
                { onUpdate: onServiceWorkerUpdate }
            );
        }
    }, [])

    useEffect(() => {
        if (!isOnline) {
            setOnlineStatus(enqueueSnackbar(
                "No Internet Connection",
                {
                    // action: action,
                    anchorOrigin: { horizontal: "right", vertical: "bottom" },
                    persist: true, variant: 'error', preventDuplicate: false
                }))
        } else {
            closeSnackbar(onlineStatus)
        }
    }, [isOnline])

    useEffect(() => {
        if (privateModus) {
            // var messageuuid = uuidv4();
            // var snackid = enqueueSnackbar("Privater Modus erkannt. Unter Umständen werden bestimmte Funktionen nicht unterstützt.", {
            //     variant: 'error',
            //     anchorOrigin: { horizontal: "right", vertical: "bottom" },
            //     persist: true,
            //     action: key => (<IconButton
            //         onClick={e => handleCloseSnackbar(messageuuid)}
            //     ><Close style={{ color: "white" }} />
            //     </IconButton>
            //     )
            // })
            // snackbarMap.set(messageuuid, snackid)
        }

    }, [privateModus])

    useEffect(() => {
        localStorage.removeItem('darkmode');
        localStorage.setItem("darkmode", true)
    }, [darkMode])

    axios.defaults.withCredentials = true
    axios.interceptors.request.use(async function (config) {
        // spinning start to show

        var valid = !sessionValid()
        var apicurl = APIURL()

        if ((valid &&
            !config.url.includes(apicurl.login) &&
            !config.url.includes(apicurl.register) &&
            !config.url.includes("changePasswordReact") &&
            !config.url.includes("savePasswordReact") &&
            !config.url.includes("resetPasswordReact") &&
            !config.url.includes("registerAIconfirm")

        )) {

            logout()
            window.location.href = '/login';

        }
        if (config?.showloading) {
            isLoading(true)
        }
        return config
    }, function (error) {
        isLoading(false)
        setError(true)
        return Promise.reject(error);
    });
    axios.interceptors.response.use(function (response) {
        // spinning hide
        isLoading(false)
        if ((Object.values(ResponseCode).includes(response.data)
            && response.data !== ResponseCode.SUCCESS && response.data !== ResponseCode.SUCCESS_CSVIMPORT)
            || response?.data?.errors?.length > 0) {
            throw response.data
        }
        return response;
    }
        ,
        async function (error, code) {
            isLoading(false)
            return Promise.reject(error);
        }
    );


    let theme = createTheme({
        typography: {
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        palette: {
            mode: darkMode ? "dark" : 'light',
            primary: {
                main: "#333340",
                other: "",
            },
            secondary: {
                main: darkMode ? "#454141" : "#ffffff",
            },
            background: {
                default: darkMode ? "#121212" : "#ffffff",
                paper: darkMode ? "#121212" : "#ffffff"
            },
            tertiary: {
                main: amber[500]
            },
            info: {
                main: blue[800]
            },
            infoLight: {
                main: false ? blue[800] : blue[100],
            },
            warning: {
                main: amber[600]
            },
            hover: {
                main: blue[400]
            },
            text: {
                main: darkMode ? "#ffffff" : "#454141",
            },
            light: {
                main: "#ffffff"
            }
        },
        tooltip: {
            fontSize: "2em",
            color: "yellow"
        },
        overrides: {
            MuiListItem: {
                root: {
                    "&$selected": {
                        background: "#333340",
                        color: "white",
                        "&:hover": {
                            background: blue[700],
                        },
                    },
                },
            },
        },
        components: {
            MuiListItem: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            backgroundColor: "#333340",
                        },
                    },
                },
            },
            MuiSvgIcon: {
                variants: [
                    {
                        props: { fontSize: 'medium' },
                        style: {
                            fontSize: '2rem',
                        },
                    },
                ],
            },


        }
    });
    theme = responsiveFontSizes(theme)
    return (
        <ThemeProvider theme={theme}>

            <GSProvider value={{
                loading: [loading, isLoading],
                loggedIn: [loggedIn, setLoggedIn],
                sessionExpired: [sessionExpired, setSessionExpired],
                broadcast: [broadcast, setBroadcast],
                errorMessage: [errorMessage, setErrorMessage]

            }}>
                <Stack>
                    <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        open={newVersionAvailable}
                        key={vertical + horizontal}
                    >
                        <Alert
                            icon={<Info fontSize="inherit" />}
                            severity="info"
                            sx={{ width: '100%' }}
                            action={<Button variant="contained" color="primary"
                                onClick={updateServiceWorker}>Update</Button>}>
                            Neue Version verfügbar!
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        style={{ marginTop: 55 }}
                        anchorOrigin={{ vertical, horizontal }}
                        open={visionalert}
                        // onClose={handleClose}
                        key={v1 + h1}
                    >
                        <Alert
                            icon={<AddAlert fontSize="inherit" />}
                            severity="error"
                            sx={{ width: '100%' }}
                            action={<Button variant="contained" color="error"
                                onClick={showAlertKamera}>SHOW</Button>}>
                            <AlertTitle>{visionalertText.title}</AlertTitle>
                            {visionalertText.body}
                        </Alert>
                    </Snackbar>
                </Stack>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center">
                    <ErrorDialog error={errorMessage !== ""} setError={setError} errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage} />

                    <LoadingDialog loading={loading} />
                    <Router>
                        <ConfirmDialog expired={sessionExpired} setExpired={setSessionExpired} />
                        <RoutingWrapper loggedIn={loggedIn} setLoggedIn={setLoggedIn} setError={setError}
                            darkMode={darkMode}
                            setDarkMode={setDarkMode}
                        />
                    </Router>
                </Box>
            </GSProvider>

        </ThemeProvider>
    );
}

function RoutingWrapper(props) {

    const { loggedIn, setLoggedIn, setError, darkMode, setDarkMode } = props
    const location = useLocation()
    const [notiOpen, setNotiOpen] = useState(false)
    const [notiText, setNotiText] = useState("SUCCESS")
    const [newMessage, setNewMessage] = useState(0)
    const messageReducer = (state, action) => {
        setNotiOpen(false)
        switch (action.type) {
            case 'ADD':
                setNotiText(action.message.content)
                setNotiOpen(true)
                setNewMessage(newMessage + 1)
                return [...state, action.message.content]
            case 'INIT':
                state = action.message
                return state
            case 'CLEAR':
                state = []
                return state
            default:
                return state;
        }
    };

    const [messages, dispatch] = useReducer(messageReducer, []);

    useEffect(() => {

    }, [newMessage])
    return (
        <>
            {
                location.pathname === "/" || location.pathname === "/learn" || location.pathname === "/register" || location.pathname === "/resetpassword" || location.pathname === "/forgotPassword" || location.pathname === "/login" ?
                    <DocumentationRootframe loggedIn={loggedIn} darkMode={darkMode}
                        setDarkMode={setDarkMode}>
                        {websiteRoutes(setLoggedIn, setError, darkMode, setDarkMode, newMessage)}
                    </DocumentationRootframe> :
                    <ResponsiveDrawer content={websiteRoutes(setLoggedIn, setError, newMessage)} loggedIn={loggedIn} />

            }
        </>
    )

}


export default App;
